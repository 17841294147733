[data-mdpersiandatetimepicker][disabled] {
    opacity: 0.5;
    cursor: default;
}

.mds-bootstrap-persian-datetime-picker-container {
    min-width: 300px;
    font-size: 12px;
    position: relative;
    overflow: visible;
    z-index: 999999;
}

.mds-bootstrap-persian-datetime-picker-container.rtl {
    direction: rtl;
}

.mds-bootstrap-persian-datetime-picker-container .select-year-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    text-align: center;
    transition: all 200ms ease;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 9999999;
}

.mds-bootstrap-persian-datetime-picker-container .select-year-box.w-0 {
    height: 0;
}

.mds-bootstrap-persian-datetime-picker-container .select-year-box button {
    font-size: 16px;
}

.mds-bootstrap-persian-datetime-picker-container .select-year-box [selected-year] button {
    background-color: aquamarine;
}

.mds-bootstrap-persian-datetime-picker-container.rtl table {
    direction: rtl;
}

.mds-bootstrap-persian-datetime-picker-container.rtl tfoot table {
    direction: ltr;
}

.mds-bootstrap-persian-datetime-picker-container.rtl .dropdown-toggle::after {
    margin-left: 0;
    margin-right: 0.255em;
}

.mds-bootstrap-persian-datetime-picker-container * {
    font-size: 12px;
}

.mds-bootstrap-persian-datetime-picker-container table {
    margin-bottom: 0;
}

.mds-bootstrap-persian-datetime-picker-container table th, .mds-bootstrap-persian-datetime-picker-container table td {
    padding: 1px;
    font-size: 14px;
    text-align: center;
}

.mds-bootstrap-persian-datetime-picker-container table tbody td[data-next-month] .btn, .mds-bootstrap-persian-datetime-picker-container table tbody td[data-prev-month] .btn {
    background: #fff;
    border: none;
    cursor: default;
}

.mds-bootstrap-persian-datetime-picker-container table table {
    background: transparent !important;
    background-color: transparent !important;
    border: none;
}

.mds-bootstrap-persian-datetime-picker-container table table th {
    border: none;
}

.mds-bootstrap-persian-datetime-picker-container table table input[type="text"] {
    width: 50px;
    border: none;
    text-align: center;
    background-color: #fff;
}

.mds-bootstrap-persian-datetime-picker-container th {
    padding: 0;
}

.mds-bootstrap-persian-datetime-picker-container table table tbody.days {
    border: 0;
}

.mds-bootstrap-persian-datetime-picker-container table table tbody.days tr:first-child>td {
    vertical-align: top;
    padding-bottom: 5px;
    width: 14.2%;
    cursor: default;
    font-weight: bold;
}

.mds-bootstrap-persian-datetime-picker-container table table tbody.days td[data-day] {
    cursor: pointer;
    transition: all 200ms ease;
}

.mds-bootstrap-persian-datetime-picker-container table table tbody.days td[data-day][data-today] {
    background-color: aquamarine;
}

.mds-bootstrap-persian-datetime-picker-container table table tbody.days td[data-day][data-selectedday] {
    background-color: #96daff;
}

.mds-bootstrap-persian-datetime-picker-container table table tbody.days td.selected-range-days-start-end {
    background-color: #c9e1ff;
}

.mds-bootstrap-persian-datetime-picker-container table table tbody.days td.selected-range-days {
    background-color: #d9f2e6;
}

.mds-bootstrap-persian-datetime-picker-container table table tbody.days td[data-pm], .mds-bootstrap-persian-datetime-picker-container table table tbody.days td[data-nm], .mds-bootstrap-persian-datetime-picker-container table table tbody.days td[disabled] {
    cursor: default;
    opacity: 0.2;
    transition: none;
}

.mds-bootstrap-persian-datetime-picker-container table table tbody.days td[data-pm]:hover, .mds-bootstrap-persian-datetime-picker-container table table tbody.days td[data-nm]:hover, .mds-bootstrap-persian-datetime-picker-container table table tbody.days td[disabled] {
    cursor: default;
    opacity: 0.3;
    transition: none;
}

.mds-bootstrap-persian-datetime-picker-container table table tbody.days td[data-day]:hover, .mds-bootstrap-persian-datetime-picker-container table table tbody.days td[data-nm]:hover, .mds-bootstrap-persian-datetime-picker-container table table tbody.days td[data-pm]:hover {
    background: #ccc;
}

.mds-bootstrap-persian-datetime-picker-container .btn {
    width: 100%;
    margin-bottom: 1px;
    padding: 1px;
    font-size: 14px;
}

.mds-bootstrap-persian-datetime-picker-container .btn[disabled] {
    opacity: 0.2;
}

.mds-bootstrap-persian-datetime-picker-container .dropdown {
    padding: 0;
    padding-bottom: 1px;
    text-align: center;
}

.mds-bootstrap-persian-datetime-picker-container .dropdown-menu {
    min-width: 100px;
    width: 100px;
}

.mds-bootstrap-persian-datetime-picker-container .dropdown-item {
    padding: 0;
    padding-bottom: 1px;
    cursor: pointer;
    text-align: center;
}

.mds-bootstrap-persian-datetime-picker-container .dropdown-item:not(.disabled):hover {
    background-color: #ccc;
}

.mds-bootstrap-persian-datetime-picker-container .dropdown-item.disabled {
    opacity: 0.2;
    cursor: default;
}

.mds-bootstrap-persian-datetime-picker-container .dropdown-divider {
    margin-bottom: 1px;
    cursor: default;
}

.mds-bootstrap-persian-datetime-picker-popover {
    max-width: 100%;
    -webkit-box-shadow: 0 5px 15px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 15px 1px rgba(0, 0, 0, 0.1);
    z-index: 999999999;
}